<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>Evolución ( <span class="small"> {{evolucion.fecha}} -  <span class="small"> {{evolucion.hora}}</span>)</span></page-header-compact>
        <div class="container-xl px-4 mt-n15">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="row gutters-sm">
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">P.A.SISTOL / P.A.DIASTOL</div>
                        <div class="card-body">                           
                            <h6>
                                <i class="fa fa-stethoscope text-info fa-fw"></i>
                                 {{evolucion.pa_sistolica}} / {{evolucion.pa_diastolica}} <span class="text-danger">(mmHg)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">PULSO</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-history text-success fa-fw"></i>
                                 {{evolucion.pulso}} <span class="text-danger">(lat/min)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">PESO</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-weight text-primary fa-fw"></i>
                                {{evolucion.peso}} <span class="text-danger">(KG)</span>
                            </h6>
                        </div>
                    </div> 
                </div>
                <div class="col-md-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">TEMPERATURA</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-thermometer text-danger fa-fw"></i>
                                 {{evolucion.temperatura}} <span class="text-danger">(°C)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">RESPIRACION</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-lungs text-info fa-fw"></i>
                                 {{evolucion.respiracion}} <span class="text-danger">(r/m)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">TALLA</div>
                        <div class="card-body">
                            <h6>
                                <i class="fa fa-ruler text-warning fa-fw"></i>
                                 {{evolucion.talla}} <span class="text-danger">(CM)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 mb-3">
                    <div class="card mt-2 text-center ">
                        <div class="card-header">SATURATCION O.</div>
                        <div class="card-body">
                            <h6>
                                <i class="fas fa-sort-amount-up text-success"></i>
                                 {{evolucion.saturacion_oxigeno}} <span class="text-danger">(%)</span>
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="card mt-2">
                        <div class="card-header">Subjetivo</div>
                        <div class="card-body">
                            <label  class="col-sm-12 col-form-label"> 
                                {{evolucion.subjetivo}}
                            </label>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-header">Objetivo</div>
                        <div class="card-body">
                            <label  class="col-sm-12 col-form-label"> 
                                {{evolucion.objetivo}}
                            </label>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-header">Analisis</div>
                        <div class="card-body">
                            <label  class="col-sm-12 col-form-label"> 
                                {{evolucion.analisis}}
                            </label>
                        </div>
                    </div>
                    <div class="card mt-2">
                        <div class="card-header">Plan</div>
                        <div class="card-body">
                            <label  class="col-sm-12 col-form-label"> 
                                {{evolucion.plan}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import urgenciaEvolucionService from '../../../../services/urgenciaEvolucionService';
import modalInformacionUsuario from "../informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../informacionUsuario/cardInformacionUsuario";
import historiaClinicaService from '../../../../services/historiaClinicaService';

export default {
    components: {PageHeaderCompact,modalInformacionUsuario,cardInformacionUsuario},
    data(){
        return {
            evolucion: {},
            usuario: {},
        }
    },
    methods: {
        async cargarEvolucion(id) {
            const response = await urgenciaEvolucionService.show(id);
            this.evolucion = response.data;
        },
        async cargarUsuario(id) {
            
            const response = await historiaClinicaService.show(id);
            this.usuario = response.data.usuario;
            
        },
       
    },
    async created(){
        this.LoaderSpinnerShow();
        await this.cargarEvolucion(this.$route.params.id); 
        await this.cargarUsuario(this.evolucion.id_historia);
        this.LoaderSpinnerHide();
    }
}
</script>